import Autoplay from './shared/autoplay'
import Animations from './shared/animations'
import Config from './shared/config'
import CookieMsg from './shared/cookiemessage'
import Countdown from './shared/countdown'
import CustomSelect from './shared/custom-select'
import DragScroll from './shared/drag-scroll'
import Faq from './shared/faq'
import Footer from './shared/footer'
import Header from './shared/header'
import Logger from './shared/logger'
import Login from './shared/login'
import Modal from './shared/modal'
import Navigation from './shared/navigation'
import Referral from './shared/referral'
import Session from './shared/session'
import ShowMore from './shared/show-more'
import Carousel from './shared/carousel'
import Signup from './shared/signup'
import Utility from './shared/utility'
import SearchRedirect from './shared/search-redirect';

window.Autoplay = Autoplay;
window.Animations = Animations;
window.Config = Config;
window.CookieMsg = CookieMsg;
window.Countdown = Countdown;
window.CustomSelect = CustomSelect;
window.DragScroll = DragScroll;
window.Faq = Faq;
window.Footer = Footer;
window.Header = Header;
window.Logger = Logger;
window.Login = Login;
window.Modal = Modal;
window.Navigation = Navigation;
window.Referral = Referral;
window.Session = Session;
window.ShowMore = ShowMore;
window.Carousel = Carousel;
window.Signup = Signup;
window.Utility = Utility;
window.SearchRedirect = SearchRedirect;

$(function () {
    Logger.init();
    Autoplay.init();
    Animations.init();
    CookieMsg.init();
    Countdown.init();
    CustomSelect.init();
    DragScroll.init()
    Faq.init();
    Footer.init();
    Header.init();
    Login.init();
    Modal.init();
    Navigation.init();
    Referral.init();
    Carousel.init();
    Signup.init();
    Utility.init();
    ShowMore.init();
    SearchRedirect.init();
});
